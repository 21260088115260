import * as t from "../types";

import update from "immutability-helper";

const initialState = {
  KynaAppData: {
    siteName: "PMHA",
    urlParams: {},
    route: {
      controller: "",
      activeNav: "",
    },
  },
  formData: {},
  user: {},
  name: "guest",
  themeMode: "Light", //or Dark
  serverValidation: {},
  submitIsLoading: false,
};

const mainReducer = (state = initialState, action) => {
  // console.log("action", action)
  switch (action.type) {
    case t.UPDATE_APP_SETUP:
      // return {
      //     ...state,
      //     KynaAppData: action.payload
      // };
      return update(state, {
        KynaAppData: {
          [action.payload.id]: {
            $set: action.payload.data,
          },
        },
      });

    case t.UPDATE_FORM_DATA:
      // return {
      //     ...state,
      //     KynaAppData: action.payload
      // };
      return update(state, {
        formData: {
          [action.payload.id]: {
            $set: action.payload.data,
          },
        },
      });
    case t.SET_USER:
      // return {
      //     ...state,
      //     user: action.payload
      // };
      return update(state, {
        user: {
          [action.payload.id]: {
            $set: action.payload.data,
          },
        },
      });
    case t.SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case t.SERVER_VALIDATION_ERROR:
      return {
        ...state,
        serverValidation: action.payload,
      };
    case t.SUBMIT_IS_LOADING:
      return {
        ...state,
        submitIsLoading: action.payload,
      };
    default:
      return { ...state };
  }
};

export default mainReducer;
