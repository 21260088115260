import { useNProgress } from '@tanem/react-nprogress'

const Loading = ({
  isRouteChanging,
}) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isRouteChanging,
  })

  return (
    <>
      <style jsx>{`
        .container-progress {
          opacity: ${isFinished ? 0 : 1};
          pointerevents: none;
          transition: opacity ${animationDuration}ms linear;
        }

        .bar {
          background: #2FB0CF;
          height: 2px;
          left: 0;
          margin-left: ${(-1 + progress) * 100}%;
          position: fixed;
          top: 0;
          transition: margin-left ${animationDuration}ms linear;
          width: 100%;
          z-index: 1031;
        }

        .spinner {
          display: block;
          height: 18px;
          opacity: 1;
          position: fixed;
          right: 15px;
          top: 15px;
          width: 18px;
          z-index: 1080;
          
          border-left: 2px solid #2FB0CF;
          border-radius: 50%;
          border-bottom: 2px solid transparent;
          border-right: 2px solid transparent;
          border-top: 2px solid #2FB0CF;
          box-sizing: border-box;

          // transition: 400ms linear infinite spinner;
        }
      `}</style>
      <div className="container-progress">
        <div className="bar"></div>
        <div className="spinner animate-spin-fast" />
      </div>
    </>
  )
}

export default Loading
