// import App from "next/app";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { SessionProvider } from "next-auth/react";
import { Provider } from "react-redux";
import { toast } from "react-hot-toast";
import { KNThemeError } from "@/kyna/ui/toaster/KNTheme";
import { createWrapper } from "next-redux-wrapper";
import store from "../redux/store";
import "../styles/globals.css";
import Head from "next/head";
import Loading from "@/kyna/ui/preloader/Loading";
import { ReactQueryDevtools } from "react-query/devtools";

import { QueryClient, QueryClientProvider } from "react-query";

 
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-5GZMGBN'
}


const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error) => {
        toast.error(error.message, KNThemeError);
      }
    }
  }
});

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  // START OF react-nprogress
  //#region
  const router = useRouter();
  const [state, setState] = useState({
    isRouteChanging: false,
    loadingKey: 0,
  });

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: true,
        loadingKey: prevState.loadingKey ^ 1,
      }));
    };

    const handleRouteChangeEnd = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: false,
      }));
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeEnd);
    router.events.on("routeChangeError", handleRouteChangeEnd);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeEnd);
      router.events.off("routeChangeError", handleRouteChangeEnd);
    };
  }, [router.events]);
  //#endregion
  // END OF react-nprogress

  return (
    <Provider store={store}>
      <SessionProvider session={session} refetchInterval={5 * 60}>
        <QueryClientProvider client={queryClient}>
          <Loading
            isRouteChanging={state.isRouteChanging}
            key={state.loadingKey}
          />
          <Head>
            <link rel="icon" href="/assets/img/dash-app-logo.svg" />
          </Head>
          <Component {...pageProps} />
          <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
        </QueryClientProvider>
      </SessionProvider>
    </Provider>
  );
}

// *legacy implementation
// const makeStore = () => store;
// export default withRedux(makeStore)(MyApp);

const makeStore = () => store;
const wrapper = createWrapper(makeStore);

export default wrapper.withRedux(MyApp);
