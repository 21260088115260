
export const KNThemeSuccess = {
    style: {
    //   border: '1px solid #713200',
      padding: '14px 16px',
      color: '#4b5563',
      fontFamily: 'Typold',
      fontSize: '0.938rem'
    },
    iconTheme: {
      primary: '#193E8C',
      secondary: '#dbeafe',
    },
}

export const KNThemeError = {
    style: {
    //   border: '1px solid #713200',
      padding: '14px 16px',
      color: '#4b5563',
      fontFamily: 'Typold',
      fontSize: '0.938rem',
    },
    iconTheme: {
      primary: '#9f1239',
      secondary: '#fff1f2',
    },
}

export  const KNThemeLoading = {
   style: {
    //   border: '1px solid #713200',
      padding: '14px 16px',
      color: '#4b5563',
      fontFamily: 'Typold',
      fontSize: '0.938rem',
    },
    iconTheme: {
      primary: '#ebdf00',
      secondary: '#fff1f2',
    },
}

